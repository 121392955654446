import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundTitle = '404: Not found';

const NotFoundPage = () => (
	<Layout pageTitle={NotFoundTitle}>
		<SEO title={NotFoundTitle} />
		<h1>404 - NOT FOUND</h1>
		<p>Uh-oh! Looks like this page doesn't exist, or got moved. 😢</p>
		<p>
			Try using the directory listing menu (top left button) to find another page, or the "about" screen (top
			right button) for contact info.
		</p>
	</Layout>
);

export default NotFoundPage;
